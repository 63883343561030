<template>
    <v-container fluid>
        <v-card flat>
            <v-card-title>
                    <h2>Acompanhamento de Produtividade e Faturamento</h2>
            </v-card-title>
        </v-card>
        <v-tabs v-model="tab">
            <v-tab>Produtividade</v-tab>
            <v-tab>Faturamento</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <Produtividade />
            </v-tab-item>
            <v-tab-item>
                <Faturamento />
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>
<script>
export default {
    name: 'producao_produtividade_equipe_multi.vue',
    components: {
        Produtividade: () => import('./produtividade_equipe_multi.vue'),
        Faturamento: () => import('./faturamento_equipe_multi.vue'),
    },
    data: () => ({
        tab: 0,
    }),
    methods: {

    }, computed: {

    }, mounted() {
    }

}
</script>